import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Direction } from '../moveItem';

const MoveItemUIContainer = styled.div`
  display: flex;
  width: 1em;
  flex-direction: column;
`;

interface MoveItemUIProps {
  visible: boolean;
  moveItem: (direction: Direction) => void;
}

const MoveItemUI: React.FC<MoveItemUIProps> = ({ visible, moveItem }) => (
  <MoveItemUIContainer>
    {visible && (
      <>
        <FontAwesomeIcon
          icon={faCaretUp}
          onClick={(): void => moveItem('up')}
        />
        <FontAwesomeIcon
          icon={faCaretDown}
          onClick={(): void => moveItem('down')}
        />
      </>
    )}
  </MoveItemUIContainer>
);

export default MoveItemUI;
