import {
  Topic,
  Subtopic,
  Exercise,
  CollectionIds,
  getCollectionRef,
} from '@mestr/firebase';
import shortid from 'shortid';

/**
 * "The name of the thing!" => "the_name_of_the_thi_219i30sdf"
 */
export const createId = (name: string): string =>
  name
    .replace(/[^A-Z0-9]/gi, '_')
    .toLowerCase()
    .slice(0, 32)
    .concat(`_${shortid.generate()}`);

type Data = Topic | Subtopic | Exercise;

/**
 * Posts a new document to Firestore.
 * @param {Data} data The Topic | Subtopic | Exercise to submit. Includes id and documentData.
 * @param {CollectionIds} collectionIds Ids required to identify the collection in wich the new document shall live
 * @returns {Promise<void>} Resolves on success.
 */
export async function submitDocument(
  data: Data,
  collectionIds: CollectionIds
): Promise<void> {
  const collectionRef = getCollectionRef(collectionIds);

  const { id, ...documentData } = data;

  // adds new item and creates a reference to it
  const newDocref = collectionRef.doc(id);

  newDocref.get().then((snapshot) => {
    if (snapshot.exists) {
      // eslint-disable-next-line
      console.warn('There already exists a document with that name!');
    } else {
      newDocref
        .set(documentData)
        .then(() => {
          return Promise.resolve();
        })
        .catch((error: Error) => {
          console.warn('Could not submit document!');
          console.warn(error.message);
        });
    }
  });
}
