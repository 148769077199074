import styled from 'styled-components';
import { StyledCellProps } from '..';

export const ImageCellStyled = styled.div.attrs<StyledCellProps>(
  ({ testid }) => ({ 'data-testid': testid })
)<StyledCellProps>`
  position: relative;
  margin: 0.5em;
  padding: 1em;
  height: auto;
  border-radius: 5px;
  background-color: ${(props) => props.theme.admin.light};
  color: ${(props) => props.theme.adminWhite};

  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  max-width: 100%;
`;
