import styled from 'styled-components';
import { ViewMode } from './state';

export const Container = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
`;

export const Grid = styled.div<{ view: ViewMode }>`
  display: grid;
  grid-template-columns: ${({ view }) => {
    switch (view) {
      case ViewMode.EDITOR:
        return '100% 0%';
      case ViewMode.PREVIEW:
        return '0% 100%';
      case ViewMode.SPLIT:
      default:
        return '50% 50%';
    }
  }};
  grid-template-rows: auto;
  grid-template-areas: 'editor preview';
  height: 100%;

  * {
    box-sizing: border-box;
  }
`;

export const EditorArea = styled.div.attrs({
  'data-testid': 'editor-area',
})`
  grid-area: editor;
  background-color: ${({ theme }) => theme.admin.primary};
  padding: 1em;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    &:last-child {
      margin-bottom: 100px;
    }
  }

  p {
    font-size: 1.25em;
    color: ${({ theme }) => theme.adminWhite};
  }
`;
