import styled from 'styled-components';

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Item = styled.li<{ highlighted?: boolean; bordered?: boolean }>`
  font-family: ${(props) => props.theme.fonts.gothamBook};
  color: ${(props) =>
    props.highlighted ? props.theme.admin.highlight : 'inherit'};
  hyphens: auto;

  border-bottom: ${(props) =>
    props.bordered ? `1px solid ${props.theme.admin.light}` : 'none'};

  padding: 1em;

  list-style: none;
  user-select: none;

  p {
    font-size: 1.25em;
  }

  button {
    width: 100%;
  }
`;

export const SubItem = styled.li.attrs(
  ({ isSelected }: { isSelected: boolean }) => ({
    'data-testid': isSelected ? 'selected-item' : '',
  })
)<{ isSelected: boolean }>`
  display: block;
  list-style: none;
  font-family: 'Open Sans', sans-serif;
  padding: 0.25em 2em;
  background-color: ${(props) =>
    props.isSelected ? props.theme.secondary : 'transparent'};
  color: ${(props) => (props.isSelected ? 'white' : 'inherit')};
  user-select: none;

  &:first-of-type {
    margin-top: 0.75em;
  }
`;
