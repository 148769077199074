import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectTopic, selectSubtopic, SelectionState } from '@mestr/reducers';
import {
  defaultContent,
  submitDocument,
  createId,
} from '../../../../assets/utils';
import moveItem, { Direction } from '../../moveItem';

import SubtopicListView from './SubtopicListView';
import { Subtopic, Topic, useSubtopics } from '@mestr/firebase';
import { RootState } from '../../../../store';

const SubtopicListContainer: React.FC<{ topic: Topic }> = ({ topic }) => {
  const { subject, subtopic } = useSelector<RootState, SelectionState>(
    (state) => state.selection
  );
  const dispatch = useDispatch();

  const [subtopics, , subtopicsError] = useSubtopics(subject?.id, topic?.id);

  useEffect(() => {
    // Updates selected subtopic in state when subtopic is updated after saving changes
    const updatedSubtopic = subtopic ? subtopics.find((value) => value.id === subtopic.id) || null : null;
    dispatch(selectSubtopic(updatedSubtopic))
  }, [dispatch, subtopic, subtopics])

  const handleSubtopicSelect = (subtopic: Subtopic): void => {
    dispatch(selectTopic(topic));
    dispatch(selectSubtopic(subtopic));
  };

  const [showInput, setShowInput] = useState<boolean>(false);

  function toggleInput(): void {
    setShowInput(!showInput);
  }

  const handleSubmit = (name: string): void => {
    if (!subject || !topic || name.trim().length < 2) {
      return;
    }
    const id = createId(name);
    const data: Subtopic = {
      id,
      name,
      compendium: defaultContent(),
      index: subtopics.length,
    };
    submitDocument(data, { subjectId: subject.id, topicId: topic.id }).then(
      () => {
        handleSubtopicSelect(data);
      }
    );
  };

  const handleKeyDownInput = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      toggleInput();
      handleSubmit(event.currentTarget?.value);
    } else if (event.key === 'Escape') {
      toggleInput();
    }
  };

  const moveSubtopic = (subtopic: Subtopic, direction: Direction): void => {
    if (!subject || !topic) return;
    const collectionIds = { subjectId: subject.id, topicId: topic.id };
    moveItem(subtopics, subtopic, direction, collectionIds);
  };

  return (
    <>
      {<p style={{ color: 'red' }}>{subtopicsError}</p>}
      {subtopics && (
        <SubtopicListView
          subtopics={subtopics}
          showInput={showInput}
          toggleInput={toggleInput}
          onSubtopicClick={handleSubtopicSelect}
          selectedSubtopic={subtopic}
          handleKeyDownInput={handleKeyDownInput}
          moveSubtopic={moveSubtopic}
        />
      )}
    </>
  );
};

export default SubtopicListContainer;
