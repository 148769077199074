import React, { KeyboardEvent, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createId, submitDocument } from '../../../assets/utils';
import {
  TopicSidebarWrapper,
  SidebarHeader,
  Item,
  SubInput,
  Button,
} from '../SidebarStyled';
import moveItem, { Direction } from '../moveItem';
import TopicAccordion from './TopicAccordion';
import { Topic, useTopics } from '@mestr/firebase';
import { SelectionState } from '@mestr/reducers';
import { RootState } from '../../../store';

/**
 * Displays a list of topic accordions with subtopics inside,
 * and a toggleable input for adding new topics.
 */
const TopicSidebarContainer: React.FC = () => {
  const { subject } = useSelector<RootState, SelectionState>(
    (state) => state.selection
  );
  const [topics, topicsError] = useTopics(subject?.id);

  const [selected, setSelected] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);
  const [showInput, setShowInput] = useState(false);
  const toggleInput = (): void => setShowInput(!showInput);

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  const handleSubmit = (name: string): void => {
    if (!subject || name.trim().length < 2) {
      return;
    }

    const id = createId(name);
    const data: Topic = { id, name, index: topics.length };
    submitDocument(data, { subjectId: subject.id }).then(() => {
      setSelected(id);
    });
  };

  function handleKeyDownInput(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      toggleInput();
      handleSubmit(event.currentTarget.value);
    } else if (event.key === 'Escape') {
      toggleInput();
    }
  }

  const moveTopic = (topic: Topic, direction: Direction): void => {
    if (!subject) {
      return;
    }
    const collectionIds = { subjectId: subject.id };
    moveItem(topics, topic, direction, collectionIds);
  };

  return (
    <TopicSidebarWrapper>
      <SidebarHeader>Topics</SidebarHeader>
      {<p style={{ color: 'red' }}>{topicsError}</p>}
      {topics?.map((topic) => (
        <TopicAccordion
          key={topic.id}
          isOpen={selected === topic.id}
          setIsOpen={setSelected}
          topic={topic}
          moveTopic={moveTopic}
        />
      ))}
      <Item bordered>
        {showInput ? (
          <SubInput ref={inputRef} onKeyDown={handleKeyDownInput} />
        ) : (
          <Button onClick={toggleInput}>+ Topic</Button>
        )}
      </Item>
    </TopicSidebarWrapper>
  );
};

export default TopicSidebarContainer;
