import React from 'react';
import { CellType } from '@mestr/firebase';
import { EditorAction, RelativePosition, setCellTypeAction, setViewAction, spawnAction, startSavingAction, ViewMode } from '../state';
import * as Styled from './Toolbar.styled';

export interface ToolbarProps {
  dispatch: (action: EditorAction) => void;
  isSaving: boolean;
  unsavedChanges: boolean;
  view: ViewMode;
  activeCell: string | null;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  dispatch,
  isSaving,
  unsavedChanges,
  view,
  activeCell,
}) => {
  function preventStealingFocus(event: React.MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <Styled.Menu onMouseDown={preventStealingFocus}>
      <Styled.SubMenu>
        {isSaving ? (
          <Styled.LoadingButton />
        ) : (
            <Styled.SaveButton
              disabled={!unsavedChanges}
              onClick={(): void => dispatch(startSavingAction())}
            />
          )}
        <Styled.NewCellButton
          onClick={(): void => dispatch(spawnAction(RelativePosition.UNDER))}
          disabled={!activeCell}
        />
        <Styled.NewImageButton
          onClick={(): void => dispatch(spawnAction(RelativePosition.UNDER, CellType.IMAGE))}
          disabled={!activeCell}
        />
        <Styled.NewVideoButton
          onClick={(): void => dispatch(spawnAction(RelativePosition.UNDER, CellType.VIDEO))}
          disabled={!activeCell}
        />
        <Styled.TextButton
          onClick={(): void => dispatch(setCellTypeAction(CellType.TEXT))}
        />
        <Styled.MathButton
          onClick={(): void => dispatch(setCellTypeAction(CellType.MATH))}
        />
        <Styled.BoxButton
          onClick={(): void => dispatch(setCellTypeAction(CellType.BOX))}
        />
      </Styled.SubMenu>

      <Styled.SubMenu>
        <Styled.EditButton
          onClick={(): void => dispatch(setViewAction(ViewMode.EDITOR))}
          active={view === ViewMode.EDITOR}
        />
        <Styled.SplitViewButton
          onClick={(): void => dispatch(setViewAction(ViewMode.SPLIT))}
          active={view === ViewMode.SPLIT}
        />
        <Styled.PreviewButton
          onClick={(): void => dispatch(setViewAction(ViewMode.PREVIEW))}
          active={view === ViewMode.PREVIEW}
        />
      </Styled.SubMenu>
    </Styled.Menu>
  );
}
