import styled from 'styled-components';

export const VideoContainer = styled.div`
  position: relative;
  margin: 0.5em;
  padding: 1em;
  height: auto;
  border-radius: 5px;
  background-color: ${(props) => props.theme.admin.light};
  color: ${(props) => props.theme.adminWhite};

  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
`;

export const VideoInput = styled.input`
  background-color: ${(props) => props.theme.admin.dark};
  /* margin: 0 1em; */
  outline: none;
  flex: 1 1 auto;
  border: none;
  color: ${(props) => props.theme.adminWhite};
`;

export const Popup = styled.div`
  border-radius: 2em;
  position: absolute;
  background-color: ${(props) => props.theme.adminWhite};
  color: black;
  padding: 1em;
  top: 0;
  right: 0;

  h1 {
    font-size: 1.5em;
    font-weight: bold;
  }
  ol {
    list-style-position: inside;
  }
  p {
    color: black;
  }
`;
