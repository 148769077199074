import React, { useState, useEffect } from 'react';
import { changeAction, setActiveCellAction, deleteAction, EditorAction, keydownAction, moveAction, RelativePosition, spawnAction } from '../../state';
import { InputCellStyled } from './InputCell.styled';

export interface InputCellProps {
  testid?: string;
  cellId: string;
  value: string;
  isActive: boolean;
  prevKey: string | null;
  sectionIndex: number;
  dispatch: (action: EditorAction) => void;
}

export const InputCell: React.FC<InputCellProps> = ({
  testid,
  cellId,
  value,
  isActive,
  prevKey,
  sectionIndex,
  dispatch,
}) => {
  const [textarea, setTextarea] = useState<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    /*
    It's fine and dandy that the reducer is keeping track of which cell is supposed to be active,
    but if the actual text areas aren't focused this does not have any effect. The isActive
    prop is true for the one cell (in a section) that is active and false for the others. When
    a cell detects - with this useEffect - that its isActive prop is true, it focuses
    its textarea. This might break with multiple sections.

    BE WARNED: This behavior is supremely annoying to unit test so it's currently untested.
    */
    if (isActive && textarea !== null) {
      textarea.focus();
      if (['ArrowUp', 'ArrowLeft', 'Backspace'].includes(prevKey ?? '')) {
        textarea.setSelectionRange(value.length, value.length + 1);
      } else if (['ArrowRight', 'ArrowDown'].includes(prevKey ?? '')) {
        textarea.setSelectionRange(0, 0);
      }
    }
    /* Disable this rule because we do not want the useEffect to trigger when prevKey changes. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, textarea]);

  /*
  List over modifier keys on Windows and OS X
            Windows	  OS X
  shiftKey	Shift	    Shift
  ctrlKey	  Ctrl	    Command
  altKey    Alt	      Alt
  metaKey   Windows	  Control
  */
  function onKeydown(event: React.KeyboardEvent<HTMLTextAreaElement>): void {
    dispatch(keydownAction(event.key));
    switch (event.key) {
      case 'Enter':
        if (
          event.shiftKey &&
          !event.altKey &&
          !event.ctrlKey &&
          !event.metaKey
        ) {
          event.preventDefault();
          dispatch(spawnAction(RelativePosition.UNDER));
          return;
        }

        if (
          event.shiftKey &&
          event.altKey &&
          !event.ctrlKey &&
          !event.metaKey
        ) {
          event.preventDefault();
          dispatch(spawnAction(RelativePosition.OVER));
          return;
        }
        break;
      case 'Backspace':
        if (value === '') {
          event.preventDefault();
          dispatch(deleteAction());
        }
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        if (textarea?.selectionStart === 0 && textarea?.selectionEnd === 0) {
          event.preventDefault();
          dispatch(moveAction(RelativePosition.OVER));
        }
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        if (textarea && textarea?.selectionStart >= value.length - 1) {
          event.preventDefault();
          dispatch(moveAction(RelativePosition.UNDER));
        }
        break;
    }
  }

  return (
    <InputCellStyled
      suppressContentEditableWarning
      ref={(tag): void => setTextarea(tag)}
      testid={`${cellId},${testid ?? ''},`}
      value={value}
      onChange={(e): void => dispatch(changeAction(e.target.value))}
      onFocus={(): void => dispatch(setActiveCellAction(sectionIndex, cellId))}
      onBlur={(): void => dispatch(setActiveCellAction(0, ''))}
      onKeyDown={onKeydown}
    />
  );
};
