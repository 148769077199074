import React from 'react';

export function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: (...args: unknown[]) => unknown
): void {
  React.useEffect(() => {
    /**
     * Run callback if clicked on outside of element
     */
    function handleClickOutside(this: Document, ev: MouseEvent) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ref?.current && !ref.current.contains(ev.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref]);
}
