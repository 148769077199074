import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useProtectedSubjects } from '@mestr/firebase';
import { Box, SelectButton } from './Home.styled';

const SubjectList: React.FC = () => {
  const [subjects, loading] = useProtectedSubjects();

  return (
    <Box>
      <h3>Your subjects</h3>
      {loading ? <SelectButton><Skeleton /></SelectButton> : subjects.length > 0 ? (
        subjects.map((subject) => (
          <SelectButton
            key={subject.id}
            as={Link}
            to={`/${subject.id}/edit`}
            type="button"
          >
            {subject.name}
          </SelectButton>
        ))
      ) : (
          <p>Sorry, you do not have permission to access any subjects.</p>
        )}
      <p>
        Interested in creating your own compendium?{' '}
        <a href="mailto:kontakt@mestr.no">Contact us!</a>
      </p>
    </Box>
  )
}

export default SubjectList
