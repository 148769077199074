import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { VideoContainer, VideoInput } from './VideoCell.styled'
import { faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { changeAction, deleteAction, EditorAction, setActiveCellAction } from '../../state'
import { IconButton } from '@mestr/components'
import VideoHelp from './VideoHelp'

interface Props {
  cellId: string;
  value: string;
  sectionIndex: number;
  dispatch: (action: EditorAction) => void;
}

const VideoCell: React.FC<Props> = ({
  cellId,
  value,
  sectionIndex,
  dispatch,
}) => {
  const [showHelper, setShowHelper] = useState(false);

  return (
    <VideoContainer
      onMouseDown={(): void => dispatch(setActiveCellAction(sectionIndex, cellId))}
      onBlur={(): void => dispatch(setActiveCellAction(0, ''))}
    >
      <p>Youtube ID:</p>
      <VideoInput
        value={value}
        onChange={(e): void => dispatch(changeAction(e.target.value))}
      />
      <IconButton onClick={(): void => setShowHelper(true)}>
        <FontAwesomeIcon icon={faQuestionCircle} color="white" />
      </IconButton>
      <IconButton onClick={(): void => dispatch(deleteAction())}>
        <FontAwesomeIcon icon={faTrash} color="white" />
      </IconButton>
      {showHelper && <VideoHelp onClose={(): void => setShowHelper(false)} />}
    </VideoContainer>
  )
}

export default VideoCell
