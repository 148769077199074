import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFont,
  faSquareRootAlt,
  faSave,
  faColumns,
  faEdit,
  faEye,
  faSpinner,
  faCheck,
  faPlus,
  faBoxOpen,
} from '@fortawesome/free-solid-svg-icons';
import { Rotate } from '@mestr/components';

export const Menu = styled.nav`
  grid-area: toolbar;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.admin.primary};
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  z-index: 1;
`;

export const SubMenu = styled.div`
  & > * {
    margin: 0 0.5em;
  }
`;

interface ToolbarButtonProps {
  active?: boolean;
  centered?: boolean;
  inactive?: boolean;
  disabled?: boolean;
  onClick?: () => unknown;
}

interface RootToolbarButtonProps extends ToolbarButtonProps {
  testid: string;
}

export const ToolbarButton = styled.button.attrs<RootToolbarButtonProps>(
  ({ testid, disabled }) => ({
    type: 'button',
    'data-testid': testid,
    disabled
  })
) <RootToolbarButtonProps>`
  background-color: ${({ theme }) => theme.admin.light};

  font-size: 0.9em;
  color: ${({ active, disabled, theme }) => {
    if (disabled) return 'grey';
    if (active) return theme.admin.highlight;
    return theme.adminWhite;
  }};
  text-decoration: none;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};

  border: none;
  border-radius: 2px;
  padding: 0.8em 1em;

  cursor: pointer;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.admin.highlight};
    outline-offset: -5px;
  }
`;

export enum ButtonType {
  TEXT = 'TEXT',
  MATH = 'MATH',
  BOX = 'BOX',
  SAVE = 'SAVE',
  EDIT = 'EDIT',
  SPLITVIEW = 'SPLITVIEW',
  PREVIEW = 'PREVIEW',
  LOADING = 'LOADING',
  NEWCELL = 'NEWCELL',
  NEWIMAGE = 'NEWIMAGE',
  NEWVIDEO = 'NEWVIDEO',
}

export const TextButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.TEXT}>
    <FontAwesomeIcon icon={faFont} />
    &nbsp;&nbsp;Text
  </ToolbarButton>
);

export const MathButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.MATH}>
    <FontAwesomeIcon icon={faSquareRootAlt} />
    &nbsp;&nbsp;Math
  </ToolbarButton>
);

export const BoxButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.BOX}>
    <FontAwesomeIcon icon={faBoxOpen} />
    &nbsp;&nbsp;Box
  </ToolbarButton>
);

export const SaveButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.SAVE}>
    <FontAwesomeIcon icon={props.disabled ? faCheck : faSave} />
  </ToolbarButton>
);

export const EditButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.EDIT}>
    <FontAwesomeIcon icon={faEdit} />
  </ToolbarButton>
);

export const SplitViewButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.SPLITVIEW}>
    <FontAwesomeIcon icon={faColumns} />
  </ToolbarButton>
);

export const PreviewButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.PREVIEW}>
    <FontAwesomeIcon icon={faEye} />
  </ToolbarButton>
);

export const LoadingButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} disabled testid={ButtonType.LOADING}>
    <Rotate>
      <FontAwesomeIcon icon={faSpinner} />
    </Rotate>
  </ToolbarButton>
);

export const NewCellButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.NEWCELL}>
    <FontAwesomeIcon icon={faPlus} />
    &nbsp;&nbsp;New cell
  </ToolbarButton>
);

export const NewImageButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.NEWIMAGE}>
    <FontAwesomeIcon icon={faPlus} />
    &nbsp;&nbsp;New image
  </ToolbarButton>
);

export const NewVideoButton: React.FC<ToolbarButtonProps> = (props) => (
  <ToolbarButton {...props} testid={ButtonType.NEWVIDEO}>
    <FontAwesomeIcon icon={faPlus} />
    &nbsp;&nbsp;New video
  </ToolbarButton>
);