import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';

export const AdminGridWrapper = styled.div<{ showSecondSidebar: boolean }>`
  flex: 1 1 auto;
  max-width: 100vw;
  display: grid;
  background-color: ${({ theme }): string => theme.admin.light};

  grid-template-columns: max(20%, 200px) max(10%, 150px) auto;
  grid-template-rows: 80px calc(100% - 80px);
  grid-template-areas: ${(props): string =>
    props.showSecondSidebar
      ? "'navbar navbar navbar' 'sb1 sb2 main'"
      : "'navbar navbar navbar' 'sb1 filler filler';"};
  gap: 1px;
  place-items: stretch stretch;

  font-family: ${(props): string => props.theme.fonts.gothamBook};

  overflow: hidden;
`;

const ButtonStyled = styled.button.attrs(() => ({
  type: 'button',
}))`
  align-self: stretch;
  width: 100px;
  border: none;
  background-color: transparent;
  color: ${(props): string => props.theme.white};
  font-size: 1.25em;

  &:focus {
    outline: 1px solid ${(props): string => props.theme.white};
    outline-offset: -5px;
  }
`;

export const UserButton = (): JSX.Element => (
  <ButtonStyled>
    <FontAwesomeIcon icon={faUser} />
  </ButtonStyled>
);

export const Fill = styled.div`
  grid-area: filler;
  background-color: ${(props): string => props.theme.admin.primary};
`;

// export const StyledLink = styled(Link)`
//   grid-area: logo;
//   /* background-color: ${(props): string => props.theme.primary}; */
//   padding-left: 2em;
//   padding-right: 4em;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   &:focus {
//     outline: 1px solid ${(props): string => props.theme.white_highlight};
//     outline-offset: -5px;
//   }
// `;
