import React, { useReducer } from 'react';
import { removeImage } from '@mestr/firebase';
import { useEffectAfterFirstRender } from '@mestr/utils';

import { Toolbar } from './Toolbar';
import { ImageCell } from './Cell/ImageCell';
import { InputCell } from './Cell/InputCell';
import { Grid, EditorArea, SectionContainer, Container } from './CelledEditor.styled';
import AdminPreview from './AdminPreview/AdminPreview';

import {
  EditorAction,
  Section,
  reducer,
  initializeState,
  resetAction,
  ActionTypes,
  successfulSaveAction,
  failedSaveAction,
  changeAction,
} from './state';
import VideoCell from './Cell/VideoCell/VideoCell';
export interface CelledEditorProps {
  sections: Section[];
  onSave: (unsavedSections: Section[]) => Promise<void>;
  onUploadImage: (image: File) => Promise<string>;
}

export const CelledEditor: React.FC<CelledEditorProps> = ({ sections, onSave, onUploadImage }) => {
  const [state, dispatch] = useReducer(reducer, sections, initializeState);

  async function dispatchWithMiddleware(action: EditorAction): Promise<void> {
    let ref: string;

    switch (action.type) {
      // case: ActionTypes.RESET_STATE:
      // Todo: Save old content before switching content
      case ActionTypes.START_SAVING:
        dispatch(action);
        try {
          await onSave(state.sections);
          dispatch(successfulSaveAction());
        } catch (error) {
          dispatch(failedSaveAction());
          console.log('Save failed', error);
        }
        break;

      case ActionTypes.UPLOAD_IMAGE:
        try {
          ref = await onUploadImage(action.payload.image)
          dispatch(changeAction(ref));
        } catch (error) {
          console.log('Failed uploading image', error);
        }
        break;

      case ActionTypes.REMOVE_IMAGE:
        try {
          await removeImage(action.payload.value)
          dispatch(changeAction(''));
        } catch (error) {
          if (error.code === 'storage/object-not-found') {
            //Object not found in storage. Remove reference from cell in firestore.
            dispatch(changeAction(''));
          }
        }
        break;

      default:
        dispatch(action);
        break;
    }
  }

  useEffectAfterFirstRender(() => {
    dispatchWithMiddleware(resetAction(sections));
  }, [sections])

  return (
    <Container>
      <Toolbar
        dispatch={dispatchWithMiddleware}
        isSaving={state.isSaving}
        unsavedChanges={state.unsavedChanges}
        view={state.view}
        activeCell={state.activeCell}
      />
      <Grid view={state.view}>
        <EditorArea>
          {state.sections.map((section, sectionIndex) => (
            <SectionContainer data-testid={`section-container-${sectionIndex}`} key={section.title}>
              <p>{section.title}</p>
              {section.document.cellOrder.map((cellId) => {
                const cell = section.document.cells[cellId];

                switch (cell.type) {
                  case 'image':
                    return (
                      <ImageCell
                        key={cellId}
                        cellId={cellId}
                        sectionIndex={sectionIndex}
                        value={cell.value}
                        dispatch={dispatchWithMiddleware}
                      />
                    );
                  case 'video':
                    return (
                      <VideoCell
                        key={cellId}
                        cellId={cellId}
                        sectionIndex={sectionIndex}
                        value={cell.value}
                        dispatch={dispatchWithMiddleware}
                      />
                    )
                  case 'box':
                  case 'math':
                  case 'text':
                  default:
                    return (
                      <InputCell
                        testid={
                          state.activeCell === cellId
                            ? 'active-cell'
                            : undefined
                        }
                        key={cellId}
                        cellId={cellId}
                        isActive={
                          state.activeCell === cellId
                        }
                        prevKey={state.prevKey}
                        value={cell.value}
                        dispatch={dispatchWithMiddleware}
                        sectionIndex={sectionIndex}
                      />
                    );
                }
              })}
            </SectionContainer>
          ))}
        </EditorArea>
        <AdminPreview sections={state.sections} activeCell={state.activeCell} />
      </Grid>
    </Container>
  );
};
