import { Subtopic } from '@mestr/firebase';
import React, { useEffect, useRef, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Direction } from '../../moveItem';

import { Item, SubInput, Button, MoveItemUI } from '../../SidebarStyled';
import { Horizontal } from '../../SidebarStyled/Item.styled';

const SubtopicListWrapper = styled.ul`
  list-style: none;

  li {
    &:first-of-type {
      padding-top: 1em;
    }

    padding: 0.5em 1em;
  }
`;

interface SubtopicListViewProps {
  subtopics: Subtopic[];
  showInput: boolean;
  selectedSubtopic: Subtopic | null;
  toggleInput: () => void;
  onSubtopicClick: (subtopic: Subtopic) => void;
  handleKeyDownInput: (event: KeyboardEvent<HTMLInputElement>) => void;
  moveSubtopic: (subtopic: Subtopic, direction: Direction) => void;
}

/**
 * Displays a list of subtopics provided as a prop,
 * the "add subtopic" button or the input. Is a
 * controlled component wrt. keeping track of
 * which list item is selected and whether to
 * render the button or the input.
 */
const SubtopicListView: React.FC<SubtopicListViewProps> = ({
  subtopics,
  showInput,
  toggleInput,
  onSubtopicClick,
  selectedSubtopic,
  handleKeyDownInput,
  moveSubtopic,
}) => {
  const inputEl = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (showInput) {
      inputEl.current?.focus();
    }
  }, [showInput]);

  return (
    <SubtopicListWrapper>
      {subtopics.map((subtopic) => (
        <Item
          key={subtopic.id}
          onClick={(): void => onSubtopicClick(subtopic)}
          highlighted={subtopic.id === selectedSubtopic?.id}
        >
          <Horizontal>
            <MoveItemUI
              visible={
                !!selectedSubtopic && subtopic.id === selectedSubtopic.id
              }
              moveItem={(direction: Direction): void =>
                moveSubtopic(subtopic, direction)
              }
            />
            <p>{subtopic.name}</p>
          </Horizontal>
        </Item>
      ))}
      {showInput ? (
        <Item>
          <SubInput ref={inputEl} onKeyDown={handleKeyDownInput} />
        </Item>
      ) : (
        <Item>
          <Button onClick={toggleInput}>+ Subtopic</Button>
        </Item>
      )}
    </SubtopicListWrapper>
  );
};

export default SubtopicListView;
