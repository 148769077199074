import styled from 'styled-components';

const Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  background-color: ${({ theme }) => theme.admin.light};

  font-size: 1.1em;
  color: ${({ theme }) => theme.white};
  text-decoration: none;
  text-align: left;

  border: none;
  border-radius: 2px;
  padding: 0.5em 1em;

  cursor: pointer;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.admin.highlight};
    outline-offset: -5px;
  }
`;

export default Button;
