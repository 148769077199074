import { useEffect, useRef } from 'react';

export function useEffectAfterFirstRender(effect: React.EffectCallback, deps: React.DependencyList | undefined) {
  const isInitialRender = useRef(true)

  useEffect(() => {
    if (!isInitialRender.current) {
      effect()
    } else {
      isInitialRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
