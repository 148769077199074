import shortid from 'shortid';
import { defaultCell } from '../../../assets/utils';
import {
  Section,
  InternalState,
  EditorAction,
  ActionTypes,
  RelativePosition,
  ViewMode,
} from './types';

import { omitFromObject } from '../../../assets/utils';

export function initializeState(sections: Section[]): InternalState {
  return {
    sections: sections,
    activeCell: null,
    activeSectionId: 0,
    prevKey: '',
    unsavedChanges: false,
    isSaving: false,
    view: ViewMode.SPLIT,
    // isLoading: false,
  };
}

/**
 * Returns next selectable (input) cell in direction.
 * @param cellOrder
 * @param activeCellIdx
 * @param dir
 */
function move(
  cellOrder: string[],
  activeCellIdx: number,
  dir: RelativePosition
): string {
  if (dir === RelativePosition.OVER) {
    // Can't move up if you're already at the top 😎
    return activeCellIdx === 0
      ? cellOrder[activeCellIdx]
      : cellOrder[activeCellIdx - 1];
  }

  // Can't move down if you're already at the bottom 🤕
  return activeCellIdx === cellOrder.length - 1
    ? cellOrder[activeCellIdx]
    : cellOrder[activeCellIdx + 1];
}

export function reducer(
  state: InternalState,
  action: EditorAction,
  verbose = false /* change this if you want console logging - useful for debøgging */
): InternalState {
  const { activeSectionId, activeCell } = state;
  const activeSection = state.sections[activeSectionId];
  const activeCellIdx = activeCell
    ? activeSection.document.cellOrder.indexOf(activeCell)
    : -1;

  let newState: InternalState = state;
  let newCellIdx: number;
  let newCellId: string;

  switch (action.type) {
    case ActionTypes.VALUE_CHANGE:
      if (!activeCell) break;
      newState = {
        ...state,
        unsavedChanges: true,
        sections: state.sections.map((section, index) =>
          index === activeSectionId
            ? {
                ...section,
                document: {
                  ...section.document,
                  cells: {
                    ...section.document.cells,
                    [activeCell]: {
                      ...section.document.cells[activeCell],
                      value: action.payload.value,
                    },
                  },
                },
              }
            : { ...section }
        ),
      };
      break;

    case ActionTypes.SET_ACTIVE_CELL:
      newState = {
        ...state,
        prevKey: null,
        activeSectionId: action.payload.sectionId,
        activeCell: action.payload.cellId,
      };
      break;

    case ActionTypes.DELETE:
      if (
        /* never delete last cell in section. */
        activeSection.document.cellOrder.length === 1 ||
        activeCellIdx === -1 ||
        !activeCell
      ) {
        break;
      }
      newState = {
        ...state,
        prevKey: 'Backspace',
        unsavedChanges: true,
        activeCell: move(
          activeSection.document.cellOrder,
          activeCellIdx,
          activeCellIdx !== 0 ? RelativePosition.OVER : RelativePosition.UNDER
        ),
        sections: state.sections.map((section, index) =>
          index === activeSectionId
            ? {
                ...section,
                document: {
                  ...section.document,
                  cellOrder: section.document.cellOrder.filter(
                    (cid) => cid !== activeCell
                  ),
                  cells: omitFromObject(
                    section.document.cells,
                    activeCell
                  ) as typeof section.document.cells,
                },
              }
            : { ...section }
        ),
      };
      break;

    case ActionTypes.MOVE:
      if (activeCellIdx === -1) break;

      newState = {
        ...state,
        activeCell: move(
          activeSection.document.cellOrder,
          activeCellIdx,
          action.payload.direction
        ),
      };
      break;

    case ActionTypes.SPAWN:
      if (activeCellIdx === -1) break; // Only allow spawn if a cell is active

      newCellIdx = {
        [RelativePosition.OVER]: activeCellIdx,
        [RelativePosition.UNDER]: activeCellIdx + 1,
        /* END: cellOrder.length */
      }[action.payload.position];

      newCellId = shortid.generate();
      newState = {
        ...state,
        unsavedChanges: true,
        activeCell: newCellId,
        sections: state.sections.map((section, index) =>
          index === activeSectionId
            ? {
                ...section,
                document: {
                  ...section.document,
                  cells: {
                    ...section.document.cells,
                    [newCellId]: defaultCell(action.payload.type),
                  },
                  cellOrder: section.document.cellOrder
                    .slice(0, newCellIdx)
                    .concat(
                      [newCellId].concat(
                        section.document.cellOrder.slice(newCellIdx)
                      )
                    ),
                },
              }
            : { ...section }
        ),
      };
      break;

    case ActionTypes.SET_CELL_TYPE:
      console.log('Set celltype', action.payload.type);
      if (!activeCell) break;
      newState = {
        ...state,
        sections: state.sections.map((section, index) =>
          index === activeSectionId
            ? {
                ...section,
                document: {
                  ...section.document,
                  cells: {
                    ...section.document.cells,
                    [activeCell]: {
                      ...section.document.cells[activeCell],
                      type: action.payload.type,
                    },
                  },
                },
              }
            : { ...section }
        ),
      };
      break;

    case ActionTypes.KEYDOWN:
      newState = {
        ...state,
        prevKey: action.payload.key,
      };
      break;

    case ActionTypes.RESET_STATE:
      newState = initializeState(action.payload.sections);
      break;

    case ActionTypes.START_SAVING:
      newState = {
        ...state,
        isSaving: true,
      };
      break;

    case ActionTypes.SAVE_SUCCESSFUL:
      newState = {
        ...state,
        isSaving: false,
        unsavedChanges: false,
      };
      break;

    case ActionTypes.SAVE_FAILED:
      newState = {
        ...state,
        isSaving: false,
        unsavedChanges: true,
      };
      break;

    case ActionTypes.SET_VIEW:
      newState = { ...state, view: action.payload.view };
      break;

    default:
      console.warn('UNHANDLED ACTION:', action);
      newState = state;
      break;
  }

  if (verbose) {
    console.log('REDUCER CALLED WITH ACTION', action);
    console.log('OLD STATE', state);
    console.log('\tActive section:', activeSection);
    console.log('NEW STATE', newState);
  }

  return newState;
}
