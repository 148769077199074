import {
  CollectionIds,
  Exercise,
  firestoreRef,
  getCollectionRef,
  Subtopic,
  Topic,
} from '@mestr/firebase';

export type Direction = 'up' | 'down';

type Items = Topic[] | Subtopic[] | Exercise[];
type Item = Topic | Subtopic | Exercise;

function moveItem(
  items: Items,
  currentItem: Item,
  direction: Direction,
  collectionIds: CollectionIds
): void {
  let newIndex = -1;
  if (direction === 'up') {
    if (currentItem.index > 0) {
      // Not already on top
      newIndex = currentItem.index - 1;
    }
  } else if (direction === 'down') {
    if (currentItem.index < items.length - 1) {
      // Not already on bottom
      newIndex = currentItem.index + 1;
    }
  }
  if (newIndex < 0) {
    return;
  }
  // Gets a reference to the given collection
  const collectionRef = getCollectionRef(collectionIds);
  // Find the item at the new index
  const otherItem = items.find((t) => t.index === newIndex);
  // Check if other Item is found
  if (!otherItem) {
    return;
  }
  // Create a write batch to do 2 writes simultanously to switch the two items
  const batch = firestoreRef.batch();
  // Set current item to new index
  batch.update(collectionRef.doc(currentItem.id), { index: newIndex });
  // Place the other item where the current item lived
  batch.update(collectionRef.doc(otherItem.id), {
    index: currentItem.index,
  });
  // Commit batch write
  batch.commit();
}

export default moveItem;
