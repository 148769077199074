import React, { useEffect } from 'react';
import {
  FullPageFlexWrapper,
  Logo,
  SmallMestrLogo,
  AuthContainer,
} from './Auth.styled';
import { AuthComponent } from '@mestr/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { RouteChildrenProps } from 'react-router-dom';

type AuthProps = RouteChildrenProps;

export const Auth = (props: AuthProps): JSX.Element => {
  const user = useSelector((state: RootState) => state.auth?.user);

  useEffect(() => {
    if (user && props.match?.path !== '/verify') {
      props.history.replace('/');
    }
  }, [user, props.history, props.match]);

  return (
    <FullPageFlexWrapper>
      <main>
        <Logo />
        <AuthContainer>
          {['/verify', '/reset-password', undefined].includes(
            props.match?.path
          ) || (
            <>
              <h1>MESTR ADMIN</h1>
              <p>
                Bli med å lage kompendium og oppgaver for dine medstudenter!
              </p>
            </>
          )}
          <AuthComponent />
        </AuthContainer>
      </main>
      <SmallMestrLogo />
    </FullPageFlexWrapper>
  );
};

export default Auth;
