import { Middleware } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

import { fetchSubject, Subject } from '@mestr/firebase';
import { selectSubject } from '@mestr/reducers';

export const locationMiddleware: Middleware = (store) => (next) => (
  action
): void => {
  if (action.type === LOCATION_CHANGE) {
    const pathname = action.payload.location.pathname;
    const match = pathname.match(/^\/[^/]+\/edit$/);
    if (match !== null) {
      const subjectId = match[0].split('/')[1];
      fetchSubject(subjectId).then((subject: Subject | null) => {
        if (subject !== null) {
          store.dispatch(selectSubject({ id: subject.id, name: subject.name }));
        }
      });
    } else {
      store.dispatch(selectSubject(null));
    }
  }
  next(action);
};

export default locationMiddleware;
