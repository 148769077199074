import styled from 'styled-components';

export const PreviewArea = styled.div.attrs({
  'data-testid': 'preview-area',
})`
  grid-area: preview;
  color: white;
  padding: 1em;
  background-color: ${({ theme }) => theme.admin.primary};
  position: relative;
  overflow-y: auto;
  > * {
    &:last-child {
      margin-bottom: 100px;
    }
  }
`;

export const PreviewCell = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.admin.light};
  min-height: 2em;
  margin-bottom: 1em;
  margin-top: 0.5em;
`;
