import React from 'react'
import { Preview } from '@mestr/components'
import { Section } from '../state'
import { PreviewArea, PreviewCell } from './AdminPreview.styled'

interface Props {
  sections: Section[];
  activeCell: string | null;
}

const AdminPreview: React.FC<Props> = ({ sections, activeCell }) => {
  return (
    <PreviewArea>
      {
        sections.map((section, index) => (
          <React.Fragment key={index}>
            <h1 style={{ fontSize: '1.25em' }}>{section.title}</h1>
            <PreviewCell>
              <Preview document={section.document} activeCell={activeCell} />
            </PreviewCell>
          </React.Fragment>
        ))
      }
    </PreviewArea>
  )
}

export default AdminPreview
