import { CelledDocument } from '@mestr/firebase';
import { CellType } from '@mestr/firebase';

export enum ViewMode {
  EDITOR = 'ViewMode/EDITOR',
  SPLIT = 'ViewMode/SPLIT',
  PREVIEW = 'ViewMode/PREVIEW',
}

export enum RelativePosition {
  OVER = 'RelativePosition/OVER',
  UNDER = 'RelativePosition/UNDER',
}

export enum ActionTypes {
  VALUE_CHANGE = 'InputCell/VALUE_CHANGE',
  KEYDOWN = 'InputCell/KEYDOWN',
  DELETE = 'InputCell/DELETE',
  SET_ACTIVE_CELL = 'InputCell/SET_ACTIVE_CELL',
  MOVE = 'InputCell/MOVE',
  SPAWN = 'InputCell/SPAWN',
  SET_CELL_TYPE = 'InputCell/SET_CELL_TYPE',
  RESET_STATE = 'RESET_STATE',
  START_SAVING = 'START_SAVING',
  SAVE_SUCCESSFUL = 'SAVE_SUCCESSFUL',
  SAVE_FAILED = 'SAVE_FAILED',
  SET_VIEW = 'SET_VIEW',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  REMOVE_IMAGE = 'REMOVE_IMAGE',
}

export interface Action<T, P> {
  type: T;
  payload: P;
}

export type EditorAction =
  | Action<ActionTypes.VALUE_CHANGE, { value: string }>
  | Action<ActionTypes.SET_ACTIVE_CELL, { sectionId: number; cellId: string }>
  | Action<ActionTypes.DELETE, null>
  | Action<ActionTypes.MOVE, { direction: RelativePosition }>
  | Action<ActionTypes.KEYDOWN, { key: string }>
  | Action<ActionTypes.SPAWN, { position: RelativePosition; type?: CellType }>
  | Action<ActionTypes.SET_CELL_TYPE, { type: CellType }>
  | Action<ActionTypes.RESET_STATE, { sections: Section[] }>
  | Action<ActionTypes.START_SAVING, null>
  | Action<ActionTypes.SAVE_SUCCESSFUL, null>
  | Action<ActionTypes.SAVE_FAILED, null>
  | Action<ActionTypes.SET_VIEW, { view: ViewMode }>
  | Action<ActionTypes.UPLOAD_IMAGE, { image: File }>
  | Action<ActionTypes.REMOVE_IMAGE, { value: string }>;

export interface Section {
  title: string;
  document: CelledDocument;
}
export interface InternalState {
  sections: Section[];
  activeCell: string | null;
  activeSectionId: number;
  unsavedChanges: boolean;
  isSaving: boolean;
  prevKey: string | null;
  view: ViewMode;
}
