import { Exercise } from '@mestr/firebase';
import React from 'react';
import { Item } from '../SidebarStyled';

interface CompendiumItemProps {
  isSelected: boolean;
  onSelect: (exercise: Exercise | null) => void;
}

const CompendiumItem: React.FC<CompendiumItemProps> = ({
  isSelected,
  onSelect,
}) => {
  function handleClick(): void {
    onSelect(null);
  }

  return (
    <Item bordered highlighted={isSelected} onClick={handleClick}>
      <p>Compendium</p>
    </Item>
  );
};

export default CompendiumItem;
