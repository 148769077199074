import {
  ActionTypes,
  EditorAction,
  RelativePosition,
  Section,
  ViewMode,
} from './types';
import { CellType } from '@mestr/firebase';

export const changeAction = (value: string): EditorAction => ({
  type: ActionTypes.VALUE_CHANGE,
  payload: { value },
});

export const setActiveCellAction = (
  sectionId: number,
  cellId: string
): EditorAction => ({
  type: ActionTypes.SET_ACTIVE_CELL,
  payload: { sectionId, cellId },
});

export const deleteAction = (): EditorAction => ({
  type: ActionTypes.DELETE,
  payload: null,
});

export const moveAction = (direction: RelativePosition): EditorAction => ({
  type: ActionTypes.MOVE,
  payload: { direction },
});

export const spawnAction = (
  position: RelativePosition,
  type?: CellType
): EditorAction => ({
  type: ActionTypes.SPAWN,
  payload: { position, type },
});

export const setCellTypeAction = (type: CellType): EditorAction => ({
  type: ActionTypes.SET_CELL_TYPE,
  payload: { type },
});

export const keydownAction = (key: string): EditorAction => ({
  type: ActionTypes.KEYDOWN,
  payload: { key },
});

export const resetAction = (sections: Section[]): EditorAction => ({
  type: ActionTypes.RESET_STATE,
  payload: { sections },
});

export const startSavingAction = (): EditorAction => ({
  type: ActionTypes.START_SAVING,
  payload: null,
});

export const successfulSaveAction = (): EditorAction => ({
  type: ActionTypes.SAVE_SUCCESSFUL,
  payload: null,
});

export const failedSaveAction = (): EditorAction => ({
  type: ActionTypes.SAVE_FAILED,
  payload: null,
});

export const setViewAction = (view: ViewMode): EditorAction => ({
  type: ActionTypes.SET_VIEW,
  payload: { view },
});

export const uploadImageAction = (image: File): EditorAction => ({
  type: ActionTypes.UPLOAD_IMAGE,
  payload: { image },
});

export const removeImageAction = (value: string): EditorAction => ({
  type: ActionTypes.REMOVE_IMAGE,
  payload: { value },
});
