import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'katex/dist/katex.min.css';

import theme from '@mestr/theme';
import '@mestr/shared/styles';

import App from './app/app';
import { configureStore } from './store';

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <SkeletonTheme
            color={theme.admin.light}
            highlightColor={theme.admin.primary}
          >
          <App />
        </SkeletonTheme>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#root')
);
