import React, { KeyboardEvent, useEffect, useRef } from 'react';
import {
  SidebarHeader,
  Item,
  Button,
  SubInput,
  MoveItemUI,
} from '../SidebarStyled';
import { Exercise } from '@mestr/firebase';
import { Direction } from '../moveItem';
import { Horizontal } from '../SidebarStyled/Item.styled';

interface ExerciseSidebarViewProps {
  exercises: Exercise[];
  showInput: boolean;
  selected: Exercise | null;
  onSelect: (exercise: Exercise) => void;
  toggleInput: () => void;
  handleKeyDownInput: (event: KeyboardEvent<HTMLInputElement>) => void;
  moveExercise: (exercise: Exercise, direction: Direction) => void;
}

const ExerciseSidebarView = ({
  exercises,
  selected,
  showInput,
  onSelect,
  toggleInput,
  handleKeyDownInput,
  moveExercise,
}: ExerciseSidebarViewProps): JSX.Element => {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showInput) {
      inputEl.current?.focus();
    }
  }, [showInput, inputEl]);

  return (
    <>
      <SidebarHeader>Exercises</SidebarHeader>
      {exercises.map((exercise) => (
        <Item
          bordered
          key={exercise.id}
          onClick={(): void => onSelect(exercise)}
          highlighted={exercise.id === selected?.id}
        >
          <Horizontal>
            <MoveItemUI
              visible={exercise.id === selected?.id}
              moveItem={(direction: Direction): void =>
                moveExercise(exercise, direction)
              }
            />
            <p>{exercise.name}</p>
          </Horizontal>
        </Item>
      ))}
      {showInput ? (
        <Item bordered>
          <SubInput ref={inputEl} onKeyDown={handleKeyDownInput} />
        </Item>
      ) : (
        <Item bordered>
          <Button onClick={toggleInput}>+ Exercise</Button>
        </Item>
      )}
    </>
  );
};

export default ExerciseSidebarView;
