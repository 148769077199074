import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuthStateListener } from '@mestr/auth';
import { PrivateRoute } from '@mestr/components';
import RootWrapper from './app.styled';
import Admin from '../pages/Admin';
import Auth from '../pages/Auth';
import Home from '../pages/Home';

export const App: React.FC = () => {
  useAuthStateListener();

  return (
    <RootWrapper>
      <Switch>
        <PrivateRoute path="/" exact component={Home} />
        <PrivateRoute path="/:id/edit" exact component={Admin} />
        <Route
          path={['/login', '/signup', '/verify', '/reset-password']}
          component={Auth}
        />
      </Switch>
    </RootWrapper>
  );
};

export default App;
