import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TopicSidebar, ExerciseSidebar } from '../../components/Sidebar';
import CelledEditor from '../../components/CelledEditor';

import { AdminGridWrapper, Fill } from './AdminStyled';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../store';
import { AdminNavbar } from '@mestr/components';
import { getCustomClaims } from '@mestr/firebase';

type AdminProps = RouteComponentProps<{ id: string }>;

const Admin: React.FC<AdminProps> = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const authUser = useSelector(({ auth }: RootState) => auth.user);
  const { subject, topic, subtopic, exercise } = useSelector(
    ({ selection: { subject, topic, subtopic, exercise } }: RootState) => ({
      subject,
      topic,
      subtopic,
      exercise,
    })
  );

  useEffect(() => {
    if (authUser === null) {
      history.replace('/login');
    }
    getCustomClaims()?.then(claims => {
      if (!claims || !subject) return;
      if (claims.role !== 'admin') {
        if (claims.role === 'moderator' && claims.subjects?.includes(subject.id)) return;
        history.replace('/');
      }
    })
  }, [authUser, history, subject]);

  return (subject) ? (
    <AdminGridWrapper showSecondSidebar={!!subtopic}>
      <AdminNavbar />
      {subject && <TopicSidebar />}
      {topic && subtopic ? (
        <>
          <ExerciseSidebar />
          <CelledEditor
            subject={subject}
            topic={topic}
            subtopic={subtopic}
            exercise={exercise}
          />
        </>
      ) : (
          <Fill />
        )}
    </AdminGridWrapper>
  ) : (
      <p>Loading...</p>
    );
};

export default Admin;
