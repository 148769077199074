import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { StyledCellProps } from '..';

export const InputCellStyled = styled(TextareaAutosize).attrs<StyledCellProps>(
  ({ testid }) => ({
    'data-testid': testid,
  })
)<StyledCellProps>`
  font-size: 1.125em;
  margin: 0.5em;
  padding: 1em;
  height: auto;
  resize: none;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.admin.light};
  color: ${({ theme }) => theme.adminWhite};

  &:focus {
    outline: 1px solid ${({ theme }) => theme.admin.highlight};
    outline-offset: -5px;
  }
`;
