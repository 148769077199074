import styled from 'styled-components';
import { BaseButton } from '@mestr/components';

export const GridWrapper = styled.div`
  flex: 1 1 auto;
  display: grid;
  background-color: ${({ theme }) => theme.accentColors.mint.primary};
  grid-template-columns: 50% 50%;
  grid-template-rows: 100px 10em auto;
  grid-template-areas: 'navbar navbar' 'spacing spacing' 'left right';
  gap: 1px;
  place-items: stretch stretch;
  overflow: hidden;
`;

export const Left = styled.article`
  grid-area: left;
  padding-left: 15em;
  padding-right: 2.5em;
  color: ${({ theme }) => theme.adminWhite};

  h1 {
    font-family: 'Gotham-Medium', sans-serif;
    font-size: 3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.5em;
  }

  p {
    font-family: 'Gotham-Book', sans-serif;
    font-size: 1.5em;
  }
`;

export const Right = styled.main`
  grid-area: right;
  padding-left: 2.5em;
  padding-right: 15em;
`;

export const Box = styled.div`
  background: ${({ theme }) => theme.admin.dark};
  color: ${({ theme }) => theme.adminWhite};
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.55);

  border-radius: 3px;

  padding: 4em 1.75em;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  h3 {
    font-family: 'Gotham-Medium', sans-serif;
    margin-bottom: 0.5em;
  }

  p {
    font-family: 'Gotham-Book', sans-serif;
    margin-top: 0.5em;

    a {
      color: ${({ theme }) => theme.accentColors.mint.primary};
    }
  }
`;

export const SelectButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.admin.light};
  color: ${({ theme }) => theme.adminWhite};

  border: none;
  border-radius: 3px;

  font-family: 'Gotham-Book', sans-serif;
  text-align: start;
  text-decoration: none;

  padding: 1.5em;
  margin: 0.5em 0;
`;
