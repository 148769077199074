import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import {
  authReducer,
  ProjectRootState,
  selectionReducer,
  SelectionState,
} from '@mestr/reducers';
// import selectorReducer from './selectorReducer';
// import { DefaultRootState } from 'react-redux';
// import authErrorReducer from './authErrorReducer';
// import editorReducer from './editorReducer';

export interface RootState extends ProjectRootState {
  router: RouterState;
  selection: SelectionState;
}

export const createRootReducer = (history: History): Reducer<RootState> =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    selection: selectionReducer,
    // authError: authErrorReducer,
    // editor: editorReducer,
  });
