import React, { Dispatch, SetStateAction } from 'react';

import { AccordionWrapper, AccordionTitle } from './AccordionStyled';
import SubtopicList from './SubtopicList';
import { Topic } from '@mestr/firebase';

interface TopicAccordionProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<string>>;
  topic: Topic;
  moveTopic: (topic: Topic, direction: 'up' | 'down') => void;
}

/**
 * Takes in a topic. Toggles whether list is visible
 * or no on title click. Flips a caret to display openness.
 */
const TopicAccordion: React.FC<TopicAccordionProps> = ({
  isOpen,
  setIsOpen,
  topic,
  moveTopic,
}) => {
  return (
    <AccordionWrapper>
      <AccordionTitle
        isOpen={isOpen}
        moveItem={(direction): void => moveTopic(topic, direction)}
        toggle={(): void => (isOpen ? setIsOpen('') : setIsOpen(topic.id))}
      >
        {topic.name}
      </AccordionTitle>

      {isOpen && <SubtopicList topic={topic} />}
    </AccordionWrapper>
  );
};

export default TopicAccordion;
