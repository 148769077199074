import React from 'react';
import { useEffectAfterFirstRender } from '@mestr/utils';
import { ImageCellStyled } from './ImageCell.styled';
import { deleteAction, EditorAction, removeImageAction, setActiveCellAction, startSavingAction, uploadImageAction } from '../../state';

export interface ImageCellProps {
  value?: string;
  cellId: string;
  sectionIndex: number;
  dispatch: (action: EditorAction) => void;
}

export const ImageCell: React.FC<ImageCellProps> = ({ value, cellId, sectionIndex, dispatch }) => {
  useEffectAfterFirstRender(() => {
    dispatch(startSavingAction());
  }, [value])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const images = event.target.files;
    if (images?.length === 1) {
      const image = images[0];
      dispatch(uploadImageAction(image))
    }
  }

  function handleDeleteImage(): void {
    if (!value) return;
    dispatch(removeImageAction(value))
  }

  function handleDeleteCell(): void {
    dispatch(deleteAction())
  }

  return (
    <ImageCellStyled
      testid="image-cell"
      onMouseDown={(): void => dispatch(setActiveCellAction(sectionIndex, cellId))}
      onBlur={(): void => dispatch(setActiveCellAction(0, ''))}
    >
      {!value ? (
        <>
          <input alt="file-input" type="file" onChange={handleChange} />
          <button type="reset" onClick={handleDeleteCell}>Delete cell</button>
        </>
      ) : (
          <>
            <p data-testid="image">image: {value}</p>
            <button onClick={handleDeleteImage}>Delete image</button>
          </>
        )}
    </ImageCellStyled>
  );
};
