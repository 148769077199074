import styled from 'styled-components';

const SidebarHeader = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.admin.light};
  color: ${(props) => props.theme.adminWhite};
  font-family: ${(props) => props.theme.fonts.gothamBook};
  padding: 0.5em 0;
  padding-left: 1em;
`;

export default SidebarHeader;
