import React, { KeyboardEvent, useState } from 'react';
import { Exercise, useExercises } from '@mestr/firebase';
import { selectExercise, SelectionState } from '@mestr/reducers';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  defaultContent,
  submitDocument,
  createId,
} from '../../../assets/utils';
import { ExerciseSidebarWrapper, SidebarHeader } from '../SidebarStyled';
import ExerciseSidebarView from './ExerciseSidebarView';
import CompendiumItem from './CompendiumItem';
import moveItem, { Direction } from '../moveItem';

const ExerciseSidebarContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { subject, topic, subtopic, exercise } = useSelector<
    RootState,
    SelectionState
  >((state) => state.selection);
  const [exercises, , error] = useExercises(
    subject?.id,
    topic?.id,
    subtopic?.id
  );

  const [showInput, setShowInput] = useState(false);
  const toggleInput = (): void => setShowInput(!showInput);

  const handleSubmit = (name: string): void => {
    if (!subject || !topic || !subtopic || name.trim().length < 1) {
      return;
    }

    const collectionIds = {
      subjectId: subject.id,
      topicId: topic.id,
      subtopicId: subtopic.id,
    };

    const id = createId(name);
    const data: Exercise = {
      id,
      name,
      q: defaultContent(),
      a: defaultContent(),
      ...collectionIds,
      index: exercises.length,
    };

    submitDocument(data, collectionIds).then(() => {
      selectExercise(data);
    });
  };

  const handleKeyDownInput = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      toggleInput();
      handleSubmit(event.currentTarget.value);
    } else if (event.key === 'Escape') {
      toggleInput();
    }
  };

  function handleSelect(exercise: Exercise | null): void {
    dispatch(selectExercise(exercise));
  }

  function moveExercise(exercise: Exercise, direction: Direction): void {
    if (!subject || !topic || !subtopic) return;
    const collectionIds = {
      subjectId: subject.id,
      topicId: topic.id,
      subtopicId: subtopic.id,
    };
    moveItem(exercises, exercise, direction, collectionIds);
  }

  return (
    <ExerciseSidebarWrapper>
      <SidebarHeader>{subtopic?.name}</SidebarHeader>
      <CompendiumItem isSelected={exercise === null} onSelect={handleSelect} />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {exercises && (
        <ExerciseSidebarView
          exercises={exercises}
          onSelect={handleSelect}
          selected={exercise}
          showInput={showInput}
          toggleInput={toggleInput}
          handleKeyDownInput={handleKeyDownInput}
          moveExercise={moveExercise}
        />
      )}
    </ExerciseSidebarWrapper>
  );
};

export default ExerciseSidebarContainer;
