import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { MoveItemUI } from '../SidebarStyled';

export const AccordionWrapper = styled.div`
  padding: 1em 0.5em;

  border-bottom: 1px solid ${(props): string => props.theme.admin.light};
`;

const AccordionTitleStyled = styled.h1`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-size: 1.4em;
  font-family: ${(props): string => props.theme.fonts.gothamMedium};
  hyphens: auto;

  user-select: none;
`;

const Caret: React.FC<{ isOpen: boolean }> = ({ isOpen }) =>
  isOpen ? (
    <FontAwesomeIcon icon={faCaretDown} />
  ) : (
    <FontAwesomeIcon icon={faCaretRight} />
  );

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface AccordionTitleProps {
  isOpen: boolean;
  toggle: () => void;
  moveItem: (direction: 'up' | 'down') => void;
}

export const AccordionTitle: React.FC<AccordionTitleProps> = ({
  isOpen,
  toggle,
  children,
  moveItem,
}) => (
  <AccordionContainer>
    <MoveItemUI visible={isOpen} moveItem={moveItem} />
    <AccordionTitleStyled onClick={toggle}>
      {children}
      <Caret isOpen={isOpen} />
    </AccordionTitleStyled>
  </AccordionContainer>
);
