import { Cell, CelledDocument } from '@mestr/firebase';
import shortid from 'shortid';
import { CellType } from '@mestr/firebase';

export function defaultCell(type?: string): Cell {
  switch (type) {
    case CellType.IMAGE:
      return { type: 'image', value: '' };
    case CellType.VIDEO:
      return { type: 'video', value: '' };
    case CellType.TEXT:
    default:
      return { type: 'text', value: '' };
  }
}

export const defaultContent = (): CelledDocument => {
  const id = shortid.generate();
  return {
    cells: { [id]: defaultCell() },
    cellOrder: [id],
  };
};
