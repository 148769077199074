import React, { useMemo } from 'react';
import { Subtopic, Exercise, Subject, Topic, saveCompendium, saveExercise, uploadImage } from '@mestr/firebase';

import { CelledEditor } from './CelledEditor';
import { defaultContent } from '../../assets/utils';
import { Section } from './state';
import shortid from 'shortid';

interface WithFirestoreProps {
  subject: Subject;
  topic: Topic;
  subtopic: Subtopic;
  exercise: Exercise | null;
}

/**
 * Deals with database stuff; fetching and saving.
 *
 * CelledEditor component does not know which backend we use,
 * instead receiving methods for fetching and saving from
 * wrapper functions (HOC pattern).
 *
 * This function implements the required functions and extends
 * CelledEditor with them.
 */
export const withFirestore = (
  Component: typeof CelledEditor
): React.FC<WithFirestoreProps> => ({
  subject,
  topic,
  subtopic,
  exercise,
}: WithFirestoreProps): JSX.Element => {
    const sections = useMemo(() => {
      if (exercise !== null) {
        return [
          { title: 'Exercise', document: exercise.q ?? defaultContent() },
          { title: 'Solution', document: exercise.a ?? defaultContent() },
          /* Not doing anything with hints atm */
        ];
      } else {
        return [
          {
            title: 'Compendium',
            document: subtopic.compendium ?? defaultContent(),
          },
        ];
      }
    }, [subtopic, exercise])

    async function handleSave(unsavedSections: Section[]): Promise<void> {
      let ref = `subjects/${subject.id}/topics/${topic.id}/subtopics/${subtopic.id}`;
      if (unsavedSections[0].title === 'Compendium') {
        const compendiumDocument = unsavedSections[0].document
        await saveCompendium(compendiumDocument, ref);
      } else if (exercise) {
        ref += `/exercises/${exercise.id}`;
        const exerciseDocuments = { q: unsavedSections[0].document, a: unsavedSections[1].document };
        await saveExercise(exerciseDocuments, ref);
      } else {
        console.log('Tried to save a section which is not compendium and no exercise is defined.')
      }
    }

    async function handleUploadImage(image: File): Promise<string> {
      const ref = `${subject.id}/${image.name}_${shortid.generate()}`;
      await uploadImage(ref, image)
      return ref;
    }

    return <Component sections={sections} onSave={handleSave} onUploadImage={handleUploadImage} />;
  };
