import { createStore, applyMiddleware, Store } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';

import { History } from 'history';
import { createRootReducer, RootState } from './createRootReducer';
import { locationMiddleware } from './locationMiddleware';

export function configureStore(history: History): Store {
  const middlewares = [
    reduxThunk,
    routerMiddleware(history),
    locationMiddleware,
  ];
  const store = createStore(
    createRootReducer(history),
    {} as RootState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  return store;
}
