import styled from 'styled-components';

const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.white};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const TopicSidebarWrapper = styled(SidebarWrapper)`
  grid-area: sb1;
  background-color: ${(props) => props.theme.admin.primary};
`;

export const ExerciseSidebarWrapper = styled(SidebarWrapper)`
  grid-area: sb2;
  background-color: ${(props) => props.theme.admin.dark};

  header {
    padding-left: 2em;
  }

  li {
    padding-left: 2em;
  }
`;
