import styled from 'styled-components';

export const Input = styled.input.attrs(() => ({
  type: 'text',
  'data-testid': 'new-item-input',
  placeholder: 'Enter name...',
}))`
  height: 24px;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.admin.light};
  border-right: none;
  border-left: none;
  margin-bottom: 25px;
  background-color: ${(props) => props.theme.admin.light};
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;

  &::placeholder {
    color: white;
  }
`;

export const SubInput = styled.input.attrs(() => ({
  type: 'text',
  'data-testid': 'new-item-input',
  placeholder: 'Enter name...',
}))`
  background-color: ${(props): string => props.theme.admin.light};
  color: ${(props): string => props.theme.white};
  outline: none;
  border: none;

  width: 100%;
  padding: 0.625em 0.5em;
  margin-left: -0.5em;

  font-family: ${(props): string => props.theme.fonts.gothamBook};
  font-size: 1em;
`;
