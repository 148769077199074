import styled from 'styled-components';
import { Icon } from '@mestr/shared/assets';
import { MestrIcon } from '@mestr/shared/assets';

export const Logo = styled(Icon)`
  height: 80%;
  width: 50%;
  box-sizing: border-box;
  padding: 2em;
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const SmallMestrLogo = styled(MestrIcon)`
  transition: all 200ms ease-in-out;
  position: absolute;
  top: 10px;
  left: 10px;
  /* border: 2px solid blue; */

  &:hover {
    transform: scale(1.05);
  }
`;

export const FullPageFlexWrapper = styled.div`
  background-color: ${({ theme }) => theme.accentColors.mint.primary};
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  main {
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: ${({ theme }) => theme.admin.dark};
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.55);
    border-radius: 3px;

    width: 75%;
    height: 75%;
    padding: 1em;

    overflow-y: auto;

    p {
      font-size: 1.25em;
      font-weight: 200;
      color: white;
    }

    h1 {
      font-family: 'Gotham-Medium', sans-serif;
      font-size: 4em;
      color: white;
      margin-bottom: 0.3em;
    }
  }
`;

export const AuthContainer = styled.div`
  width: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled.button`
  display: inline-block;
  border: none;
  border-radius: 3px;
  margin: 0;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background: #fdd835;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;

  &:hover,
  &:focus {
    background: #c6a700;
  }

  &:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
  }

  &:active {
    transform: scale(0.99);
  }
`;
