import React from 'react';
import { AdminNavbar } from '@mestr/components';
import SubjectList from './SubjectList';
import { GridWrapper, Left, Right } from './Home.styled';

export const Home: React.FC = () => {
  return (
    <GridWrapper>
      <AdminNavbar />
      <Left>
        <h1>WELCOME TO MESTR ADMIN</h1>
        <p>
          Create a digital compendium with Latex support for math syntax. Add
          excercises to make sure your students learn the material thoroughly!
          <br />
          <br />
          Please note that Mestr and Mestr Creator still is in early beta.
        </p>
      </Left>
      <Right>
        <SubjectList />
      </Right>
    </GridWrapper>
  );
};

export default Home;
