import React, { useRef } from 'react'
import { useClickOutside } from '@mestr/utils';
import { Popup } from './VideoCell.styled'

interface Props {
  onClose: () => void;
}

const VideoHelp: React.FC<Props> = ({ onClose }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, onClose)

  return (
    <Popup ref={popupRef} >
      <h1>Hvordan legge inn youtubefilm</h1>
      <ol>
        <li>Finn filmen på youtube</li>
        <li>Se i URL'en og kopier ID'en som kommer etter 'watch?v='</li>
        <li>Lim den inn i cellen</li>
      </ol>
      <p>Så enkelt var det!</p>
    </Popup>
  )
}

export default VideoHelp
